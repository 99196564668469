const SpeakersCdns = [
    "https://i.imgur.com/0oAz3lk.png",
    "https://i.imgur.com/GlaRF3B.png",
    "https://i.imgur.com/9heBUUt.png",
    "https://i.imgur.com/lx8Z3T9.png",
    "https://i.imgur.com/2TW8BT5.png",
    "https://i.imgur.com/RvIZRpP.png",
    "https://i.imgur.com/qXXKwWO.png",
    "https://i.imgur.com/BLJjcK7.png",
    "https://i.imgur.com/a2oEz3d.png"

]

export { SpeakersCdns }