import React from "react";
import { Link } from 'react-router-dom';

const Audio = () => {
    return (
        <div className="row text-center col-12 mx-auto px-0 custom-class">
            <div className="secondStatement col-12 col-md-6 m-auto">🔊Immerse yourself in the pinnacle of automotive audio excellence. Elevate your driving experience with our top-of-the-line audio equipment—where precision meets power, and every note is crystal clear. Unleash the true potential of your car's sound system. Explore our premium amplifiers, speakers, subwoofers, and more. Transform your journey into a symphony of superior sound. Upgrade to perfection. Upgrade to DiamondDesign863🔊&quot;</div>
            <div className="col-12">
                <h1 className="h1text mt-5 fw-bold fst-italic">AUDIO</h1>
            </div>
            <div className="col-12">
                <Link to="/audio/amplifiers" className="btn index-btn p-3 mt-3" role="button">AMPLIFIERS</Link>
                <Link to="/audio/speakers" className="btn index-btn p-3 mt-3" role="button">SPEAKERS</Link>
                <Link to="/audio/drivers" className="btn index-btn p-3 mt-3" role="button">DRIVERS</Link>
                <Link to="/audio/subwoofers" className="btn index-btn p-3 mt-3" role="button">SUBWOOFERS</Link>
                <Link to="/audio/supertweeters" className="btn index-btn p-3 mt-3" role="button">TWEETERS</Link>
            </div>
        </div>
    )
}

export default Audio