import { createContext, useState } from "react";

export const CartContext = createContext({
    items: [],
    getProductQuantity: () => { },
    addOneToCart: () => { },
    removeOneFromCart: () => { },
    deleteFromCart: () => { },
    getTotalCost: () => { },
});

export function CartProvider({ children }) {
    const [cartProducts, setCartProducts] = useState([]);

    function getProductQuantity(name) {
        const product = cartProducts.find((product) => product.name === name);
        return product ? product.quantity : 0;
    }

    function addOneToCart(name, price, id, category) {
        const existingProductIndex = cartProducts.findIndex((product) => product.name === name);

        if (existingProductIndex === -1) {
            setCartProducts([
                ...cartProducts,
                {
                    name: name,
                    price: price,
                    id: id,
                    category: category,
                    quantity: 1,
                },
            ]);
        } else {
            const updatedCartProducts = [...cartProducts];
            updatedCartProducts[existingProductIndex].quantity += 1;
            setCartProducts(updatedCartProducts);
        }
    }

    function removeOneFromCart(name) {
        const quantity = getProductQuantity(name)

        if (quantity == 1) {
            deleteFromCart(name)
        } else {
            setCartProducts(
                cartProducts.map(
                    product =>
                        product.name === name ? { ...product, quantity: product.quantity - 1 } : product
                )
            )
        }
    }

    function deleteFromCart(name) {
        setCartProducts(
            cartProducts =>
                cartProducts.filter(currentProduct => {
                    return currentProduct.name != name
                })
        )
    }

    function getTotalCost() {
        return cartProducts.reduce((total, cartItem) => {
            return total + cartItem.price * cartItem.quantity;
        }, 0);
    }

    function clearCart() {
        setCartProducts([]);
    }

    const contextValue = {
        items: cartProducts,
        getProductQuantity,
        addOneToCart,
        removeOneFromCart,
        deleteFromCart,
        getTotalCost,
        clearCart
    };

    return (
        <CartContext.Provider value={contextValue}>
            {children}
        </CartContext.Provider>
    );
}

export default CartProvider;