const ShowSuperTweetersCdnImgs = [
    // -----------------CT35
  ['https://i.imgur.com/AW01Gvr.png',
  'https://i.imgur.com/F1TdZVQ.png', 
  'https://i.imgur.com/la0Dsuq.png'],
    // -----------------CT357
  ['https://i.imgur.com/oy17JJe.png', 
  'https://i.imgur.com/srKafCa.png',
  'https://i.imgur.com/LrppxwK.png'],
    // ------------------CT50
  ['https://i.imgur.com/KzuPI4h.png',
  'https://i.imgur.com/6mG96X7.png',
  'https://i.imgur.com/tnmBgoB.png']
]

export { ShowSuperTweetersCdnImgs }