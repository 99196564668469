import React, { useEffect } from "react";
import { Link } from "react-router-dom"
import { imgsCdns } from "../utils/LightsCdnImgs";
import { CartContext } from "../CartContext";
import { useContext } from "react";

const Lights = (props) => {
    const products = props.lights

    const cart = useContext(CartContext)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const loaded = () => {
        return (
            <div className="row text-center col-12 mx-auto px-0 custom-class">


                <div className="col-12">
                    <h1 className="h1text mt-5 p-3 fw-bold fst-italic">LED Lights</h1>
                </div>

                {props.lights.map((lights) => {
                    const productQuantity = cart.getProductQuantity(lights.modelName)
                    return (
                        <div className="row justify-content-center mb-3 mx-auto" key={lights.id}>
                            <div className="col-md-12 col-xl-10">
                                <div className="card shadow-lg border-0 rounded-3" style={{ backgroundColor: "rgba(175, 199, 210, 1)" }}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6 col-lg-6 col-xl-6 mb-lg-0">
                                                <div className="bg-image hover-zoom ripple rounded ripple-surface">
                                                    <img src={imgsCdns[lights.id - 1]} className="card-img-top" alt="LED system" />
                                                    <a href="#!">
                                                        <div className="hover-overlay">
                                                            <div className="mask" style={{ backgroundColor: "rgba(253, 253, 253, 0.15)" }}></div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-6 col-xl-6 border-sm-start-none border-start">
                                                    <h5 className="mt-2 fw-bold">{lights.modelName}</h5>
                                                    <h4 className="mb-1 me-1">$ {lights.price}</h4>
                                                <div className="d-flex flex-column mt-4">
                                                    <Link to={`/lights/${lights.id}`} className="btn custome-card-header btn-sm" role="button">SEE DETAILS</Link>
                                                    {productQuantity > 0 ?
                                                        <>
                                                            <div className="row mt-2 justify-content-center">
                                                                <div className="col-sm-6 align-self-center">In Cart: {productQuantity}</div>
                                                                <button className="btn btn-sm qty-btn col-2 mx-2" onClick={() => cart.removeOneFromCart(lights.modelName)}>-</button>
                                                                <button className="btn btn-sm qty-btn col-2 mx-2" onClick={() => cart.addOneToCart(lights.modelName)}>+</button>
                                                            </div>
                                                        </>
                                                        :
                                                        <button className="btn btn-sm mt-1 add-btn fw-bold" type="button" onClick={() => cart.addOneToCart(lights.modelName, lights.price, lights.id, lights.category)}>
                                                            Add to cart
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    const loading = () => {
        return <div className="justify-content-center row text-center h-50 p-3 custom-class">
            <img src="/SpinLogo.svg" className="App-logo" alt="logo" />
        </div>
    }

    return (
        <div>
            {props.lights ? loaded() : loading()}
        </div>
    )
}

export default Lights