import React from 'react';
import PaypalCheckoutButton from './PaypalCheckoutButton';
import { CartContext } from "../CartContext";
import { useContext } from "react";

const Checkout = () => {
  const cart = useContext(CartContext);

  // Assuming cart.items is an array of items, summing up the total price
  const totalPrice = cart.items.reduce((total, item) => total + (item.price * item.quantity), 0);

  return (
    <div className='row d-flex justify-content-center'>
      {cart.items.map((item, index) => (
        <div key={index} className="product-details col-12">
          <h2>{item.name}</h2>
          <p>Price: ${item.price}</p>
          <p>Quantity: {item.quantity}</p>
        </div>
      ))}
      <div className="total-price col-12">
        <h3>Total Price: ${totalPrice}</h3>
      </div>
      <div className="paypal-button-container col-8">
        <PaypalCheckoutButton products={cart.items} totalPrice={totalPrice} />
      </div>
    </div>
  );
};

export default Checkout;


