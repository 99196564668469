const ShowAmplifierCdnImgs = [
    //---400.2 EVOX2 – 4Ω
    ['https://i.imgur.com/wyir6da.png',
    'https://i.imgur.com/6E0LXlp.png',
    'https://i.imgur.com/aNcaZHk.png',
    'https://i.imgur.com/GjAMOZd.png'],

    //---400.4 EVOX2 – 4Ω
    ['https://i.imgur.com/x8OMZx8.png',
    'https://i.imgur.com/exCfMgN.png',
    'https://i.imgur.com/EnMA27h.png',
    'https://i.imgur.com/7SoKzR5.png'],

    //---800.4 EVOX2 – 4Ω
    ['https://i.imgur.com/ZzJ9I1V.png',
    'https://i.imgur.com/vmW7E3Z.png',
    'https://i.imgur.com/NB1VLb6.png',
    'https://i.imgur.com/5FTws4i.png'],

    //---1000.1 EVOX2 – 1Ω&2Ω REPEAT
    ['https://i.imgur.com/ylGJXDU.png',
    'https://i.imgur.com/fTwjEMc.png',
    'https://i.imgur.com/TyxYwaI.png',
    'https://i.imgur.com/t2Dps4T.png'],

    ['https://i.imgur.com/ylGJXDU.png',
    'https://i.imgur.com/fTwjEMc.png',
    'https://i.imgur.com/TyxYwaI.png',
    'https://i.imgur.com/t2Dps4T.png'],

    //---1200.2 EVOX2 – 2Ω&4Ω REPEAT
    ['https://i.imgur.com/W9da7sC.png',
    'https://i.imgur.com/4bipsTg.png',
    'https://i.imgur.com/a4x1pkh.png',
    'https://i.imgur.com/cTsfjhq.png'],

    ['https://i.imgur.com/W9da7sC.png',
    'https://i.imgur.com/4bipsTg.png',
    'https://i.imgur.com/a4x1pkh.png',
    'https://i.imgur.com/cTsfjhq.png'],

    //---1200.4 EVOX2 – 2Ω&4Ω REPEAT
    ['https://i.imgur.com/41zemsy.png',
    'https://i.imgur.com/VxEmWPR.png',
    'https://i.imgur.com/q5xnbr9.png',
    'https://i.imgur.com/NEVoydJ.png'],

    ['https://i.imgur.com/41zemsy.png',
    'https://i.imgur.com/VxEmWPR.png',
    'https://i.imgur.com/q5xnbr9.png',
    'https://i.imgur.com/NEVoydJ.png'],

    //---1600.1 EVOX2 – 1Ω&2Ω REPEAT
    ['https://i.imgur.com/ojewQxh.png',
    'https://i.imgur.com/pVPnt1E.png',
    'https://i.imgur.com/8Yj0fh3.png',
    'https://i.imgur.com/O8J9IRH.png'],

    ['https://i.imgur.com/ojewQxh.png',
    'https://i.imgur.com/pVPnt1E.png',
    'https://i.imgur.com/8Yj0fh3.png',
    'https://i.imgur.com/O8J9IRH.png'],

    //---2400.4 EVOX2 – 2Ω&4Ω REPEAT
    ['https://i.imgur.com/pyjil47.png',
    'https://i.imgur.com/QiKBweO.png',
    'https://i.imgur.com/OBuxvXD.png',
    'https://i.imgur.com/eHdk2De.png'],

    ['https://i.imgur.com/pyjil47.png',
    'https://i.imgur.com/QiKBweO.png',
    'https://i.imgur.com/OBuxvXD.png',
    'https://i.imgur.com/eHdk2De.png'],

    //---3000.1 EVOX2 – 1Ω&2Ω REPEAT
    ['https://i.imgur.com/bUwsZDn.png',
    'https://i.imgur.com/uuNpl4k.png',
    'https://i.imgur.com/JPMlEsD.png',
    'https://i.imgur.com/kZ02giB.png'],

    ['https://i.imgur.com/bUwsZDn.png',
    'https://i.imgur.com/uuNpl4k.png',
    'https://i.imgur.com/JPMlEsD.png',
    'https://i.imgur.com/kZ02giB.png'],

    //---5000.1 EVOX2 – 1Ω&2Ω REPEAT
    ['https://i.imgur.com/vhd5mHK.png',
    'https://i.imgur.com/KGjsxsL.png',
    'https://i.imgur.com/4lcAGGy.png',
    'https://i.imgur.com/deNsbch.png'],

    ['https://i.imgur.com/vhd5mHK.png',
    'https://i.imgur.com/KGjsxsL.png',
    'https://i.imgur.com/4lcAGGy.png',
    'https://i.imgur.com/deNsbch.png'],

    //---8000.1 EVOX2 – 1Ω&2Ω REPEAT
    ['https://i.imgur.com/0imHFgA.png',
    'https://i.imgur.com/yJjzM43.png',
    'https://i.imgur.com/NzLqWPz.png',
    'https://i.imgur.com/ClQzbxY.png'],

    ['https://i.imgur.com/0imHFgA.png',
    'https://i.imgur.com/yJjzM43.png',
    'https://i.imgur.com/NzLqWPz.png',
    'https://i.imgur.com/ClQzbxY.png'],

    //---12000.1 EVOX – 1Ω&2Ω REPEAT
    ['https://i.imgur.com/MBFWN70.png',
    'https://i.imgur.com/h9AUA06.png',
    'https://i.imgur.com/4paHCs7.png',
    'https://i.imgur.com/gbusZ1F.png'],

    ['https://i.imgur.com/MBFWN70.png',
    'https://i.imgur.com/h9AUA06.png',
    'https://i.imgur.com/4paHCs7.png',
    'https://i.imgur.com/gbusZ1F.png'],
]

export { ShowAmplifierCdnImgs }