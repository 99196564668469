import { PayPalButtons } from "@paypal/react-paypal-js";
import { useState, useContext } from "react";
import { CartContext } from "../CartContext";
import { useNavigate } from "react-router-dom";

const PaypalCheckoutButton = ({ products, totalPrice }) => {
    const navigate = useNavigate()
    const { clearCart } = useContext(CartContext)
    const [paidFor, setPaidFor] = useState(false)
    const [error, setError] = useState(null)

    if (paidFor) {
        //Empty the cart------------

        //Display a success message, modal or redirect user to success page
        alert('Thank you for your purchase!')
    }

    if (error) {
        //Display error message, modal or redirect user to error page
        alert(error)
    }

    return (
        <PayPalButtons
            style={{
                color: "silver",
                height: 48,
                tagline: false,
                shape: "pill"
            }}

            //Local call: "http://localhost:3004/create-order"
            //ServerCall: `${process.env.REACT_APP_API_BASE_URL}/Order`

            createOrder={async () => {
                try {
                    const requestData = products.map((product) => ({
                        category: product.category,
                        id: product.id,
                        name: product.name,
                        price: product.price,
                        quantity: product.quantity.toString(),
                    }));

                    // console.log("Request Body:", JSON.stringify(requestData));

                    const response = await fetch('https://diamonddesign863-001-site1.itempurl.com/api/Order', {
                        method: "POST",
                        headers: {
                            "Content-Type": 'application/json'
                        },
                        body: JSON.stringify(requestData),
                    });

                    if (!response.ok) {
                        throw new Error('Failed to create order');
                    }

                    const data = await response.json();
                    // console.log("Server Response:", data);
                    return data.id;
                } catch (error) {
                    console.error("Server Error:", error);
                    throw error; // Re-throw the error to propagate it up the chain
                }
            }}


            onApprove={async (data, actions) => {
                const order = await actions.order.capture()
                alert("Thank you for your purchase")
                clearCart()
                navigate("/");
            }}
            onCancel={() => {
                //Display cancel message, modal or redirect user to cancel page or back to cart
            }}
            onError={(err) => {
                setError(err)
                console.error('PayPal Checkout onError', err)
            }}
        />
    )
}

export default PaypalCheckoutButton