import React from "react";

const HeaderIndexImg = () => {
    return (
        <div className="text-center mt-5">
            <img className="img-fluid" src="/HomeHeaderImg.png" alt="Header with company logo"/>
        </div>
    )
}

export default HeaderIndexImg