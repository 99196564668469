import React, { useEffect, useState } from "react"
import { useParams, Link } from "react-router-dom"
import { ShowLightCdnImgs } from "../utils/ShowlightCdnImgs";
import { CartContext } from "../CartContext";
import { useContext } from "react";


const ShowLight = (props) => {
    const params = useParams()
    const [light, setLight] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const lightUrl = `${props.lightsUrl}/${params.id}`
    const cart = useContext(CartContext)

    useEffect(() => {
        const fetchLight = async (props) => {
            try {
                const response = await fetch(`${lightUrl}`)
                if (!response.ok) {
                    throw new Error('Failed to fetch light')
                }
                const data = await response.json()
                setLight(data)
            } catch (error) {
                console.error('Error fetching light:', error)
            } finally {
                setIsLoading(false)
            }
        };

        fetchLight();
    }, [lightUrl]);

    if (isLoading) {
        return <div className="justify-content-center row text-center p-3 h-50 custom-class">
            {/* <h1>Loading...</h1> */}
            <img src="/SpinLogo.svg" className="App-logo" alt="logo" />
        </div>
    }

    if (!light) {
        return <div>Amplifier not found</div>;
    }

    const productQuantity = cart.getProductQuantity(light.modelName)

    return (
        <div className="row text-center col-12 mx-auto px-0 custom-class">
            <div className='col-12 custom-class'>
                <div className='col-12'>
                </div>
                <div className="row justify-content-center mb-3 mx-auto" key={light.id}>
                    <div className="col-md-12 col-xl-10">
                        <div className="card shadow-lg border-0 rounded-3" style={{ backgroundColor: "rgba(175, 199, 210, 1)" }}>
                            <div className="card-body">
                                <p className="card-text"><small className="text-body-secondary">{light.nameDescription}</small></p>
                                <div className="row">
                                    <div className="col-md-6 col-lg-6 col-xl-6 mb-lg-0">
                                        <div className="bg-image hover-zoom ripple rounded ripple-surface">
                                            {/* IMG carousel starts here This will asume that index 0 is mp4 and the rest img */}
                                            <div id="lightImageCarousel" className="carousel slide" data-bs-ride="false">
                                                <div className="carousel-inner">
                                                    {ShowLightCdnImgs[light.id - 1].map((content, index) => (
                                                        <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`}>
                                                            {typeof content === "string" && content.endsWith(".mp4") ? ( // Check if it's a video URL
                                                                <video
                                                                    controls
                                                                    autoPlay
                                                                    muted
                                                                    src={content}
                                                                    type="video/mp4"
                                                                    className="d-block video-fluid m-auto mb-5"
                                                                    alt=""
                                                                    style={{ maxWidth: "100%", height: "auto" }}
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={content}
                                                                    className="d-block img-fluid m-auto mb-5"
                                                                    alt=""
                                                                />
                                                            )}
                                                        </div>
                                                    ))}

                                                </div>
                                                <button className="carousel-control-prev" type="button" data-bs-target="#lightImageCarousel" data-bs-slide="prev">
                                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Previous</span>
                                                </button>
                                                <button className="carousel-control-next" type="button" data-bs-target="#lightImageCarousel" data-bs-slide="next">
                                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Next</span>
                                                </button>
                                            </div>

                                            {/* IMG Carousel ends here */}
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-xl-6 border-sm-start-none border-start">
                                        <h5 className="mt-2  fw-bold">{light.modelName}</h5>
                                        <h4 className="mb-1 me-1">$ {light.price}</h4>
                                        {/* Add DiscountedPrice if available */}
                                        <div className="d-flex flex-column mt-4">
                                            {productQuantity > 0 ?
                                                <>
                                                    <div className="row mt-2 justify-content-center">
                                                        <div className="col-sm-6 align-self-center">In Cart: {productQuantity}</div>
                                                        <button className="btn btn-sm qty-btn col-2 mx-2" onClick={() => cart.removeOneFromCart(light.modelName)}>-</button>
                                                        <button className="btn btn-sm qty-btn col-2 mx-2" onClick={() => cart.addOneToCart(light.modelName)}>+</button>
                                                    </div>
                                                </>
                                                :
                                                <button className="btn add-btn btn-sm mt-1" type="button" onClick={() => cart.addOneToCart(light.modelName, light.price, light.id)}>
                                                    Add to cart
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card custom-class">
                    <h5 className="card-header custome-card-header fw-bold">Advanced Features</h5>
                    <div className="card-body" style={{ backgroundColor: "rgba(175, 199, 210, 1)" }}>
                        <p className="card-text">{light.description1}</p>
                        <h5 className="card-header custome-card-header fw-bold">Kit Offers:</h5>
                        <p className="card-text">{light.description2}</p>
                        <p className="card-text"><small className="text-body-secondary">{light.modelName}</small></p>
                    </div>
                </div>
                <Link to="/lights" className="btn index-btn p-3 mt-3" role="button">BACK</Link>
            </div>
        </div>
    )

};

export default ShowLight