import React from "react";
import { Link } from 'react-router-dom';
import { GalleryCdnImgs } from "../utils/GalleryCdnImgs";
import "../styles/modal.css"
import { useState } from "react";

const Index = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalImgSrc, setModalImgSrc] = useState("");
  const [modalImgAlt, setModalImgAlt] = useState("");

  const openModal = (src, alt) => {
    setModalImgSrc(src);
    setModalImgAlt(alt);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="custom-class">
      <div className="row text-center">
        <div className="col-12">
          <h1 className="h1text mt-5 fw-bold fst-italic">WHAT WE OFFER</h1>
        </div>
        <div className="secondStatement col-12 col-md-6 m-auto">&quot;Explore limitless possibilities with our bespoke Bikes &amp; Polaris designs, offering unrivaled customization, expert craftsmanship, and a commitment to elevating your off-road experience.&quot;</div>
      </div>

      <div className="row text-center p-3">
        <div className="col-12">
          <h1 className="h1text mt-5 fw-bold fst-italic">COLLECTIONS</h1>
        </div>
        <div className="col-12">
          <Link to="/audio" className="btn index-btn p-3 mt-3" role="button">AUDIO</Link>
          <Link to="/lights" className="btn index-btn p-3 mt-3" role="button">LIGHTS</Link>
          <Link to="/body-parts" className="btn index-btn p-3 mt-3" role="button">BODY PARTS</Link>
          <Link to="/accessories" className="btn index-btn p-3 mt-3" role="button">ACCESSORIES</Link>
          <Link to="/merchandise" className="btn index-btn p-3 mt-3" role="button">MERCHANDISE</Link>
        </div>
      </div>

      <div className="row text-center pb-5">
        <div className="col-12">
          <h1 className="h1text mt-5 mb-4 fw-bold fst-italic">CUSTOM JOBS GALLERY</h1>
        </div>
        <section className="gallery">
          <div className="container-lg">
            <div className="row gy-4 row-cols-1 row-cols-sm-1 row-cols-md-2">
              {/* Maping over images and displaying */}
              {GalleryCdnImgs.slice(0, 6).map((image, index) => (
                <div className="col" key={index}>
                  <div className="image-container index-gallery">
                    <img
                      src={image.src}
                      alt={image.alt}
                      className="img-fluid rounded-1 shadow-lg"
                      onClick={() => openModal(image.src, image.alt)} // Open modal when image is clicked
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        {/* The Modal */}
        <div id="myModal" className={`modal ${modalOpen ? 'active' : ''}`} onClick={closeModal}>
          <span className="close">&times;</span>
          <div id="imageCarousel" className="carousel slide" data-bs-ride="false">
            <div className="carousel-inner">
              {GalleryCdnImgs.map((image, index) => (
                <div key={index} className={`carousel-item ${modalImgSrc === image.src ? "active" : ""}`}>
                  <img
                    src={image.src}
                    className="d-block img-fluid"
                    alt={image.alt}
                  />
                </div>
              ))}
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#imageCarousel" data-bs-slide="prev" onClick={(e) => { e.stopPropagation(); }}>
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#imageCarousel" data-bs-slide="next" onClick={(e) => { e.stopPropagation(); }}>
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Index