import React from "react";
import { useEffect, useState } from "react";
import { Routes, Route } from 'react-router-dom'
import NavBar from "./Navbar";
import HeaderIndexImg from "./HeaderIndexImg";
import HeaderAmplifiersImg from "./HeaderAmplifiersImg";
import AboveFooterImg from "./AboveFooterImg";
import Footer from "./Footer";
import Index from "../pages/Index";
import Audio from "../pages/Audio";
import Lights from "../pages/Lights";
import Amplifiers from "../pages/Amplifiers";
import SuperTweeters from "../pages/SuperTweeters";
import Speakers from "../pages/Speakers";
import Subwoofers from "../pages/Subwoofers";
import Drivers from "../pages/Drivers";
import ShowAmplifier from "../pages/ShowAmplifier";
import ShowLight from "../pages/ShowLight";
import ShowSuperTweeter from "../pages/ShowSuperTweeter";
import ShowSpeaker from "../pages/ShowSpeaker";
import ShowDriver from "../pages/ShowDrivers";
import Cancel from "../pages/Cancel";
import Success from "../pages/Success";
import CartProvider from "../CartContext";
import Breadcrumbs from "./BreadCrumbs";
import ShowSubwoofer from "../pages/ShowSubwoofers";
import Checkout from "./Checkout";
import HeaderSpeakersImg from "./HeaderSpeakersImg";

const amplifiersUrl = `${process.env.REACT_APP_API_BASE_URL}/amplifier`
const speakersUrl = `${process.env.REACT_APP_API_BASE_URL}/speaker`
const driversUrl = `${process.env.REACT_APP_API_BASE_URL}/driver`
const subwoofersUrl = `${process.env.REACT_APP_API_BASE_URL}/subwoofer`
const superTweetersUrl = `${process.env.REACT_APP_API_BASE_URL}/superTweeters`
const lightsUrl = `${process.env.REACT_APP_API_BASE_URL}/light`

const Main = () => {

    const [amplifiers, setAmplifiers] = useState(null)
    const [speakers, setSpeakers] = useState(null)
    const [drivers, setDrivers] = useState(null)
    const [subwoofers, setSubwoofers] = useState(null)
    const [superTweeters, setSuperTweeters] = useState(null)
    const [lights, setLights] = useState(null)

    const fetchData = async (url, setData) => {
        try {
            const response = await fetch(url)
            const data = await response.json()
            setData(data)

        } catch (error) {
            console.error('Fetch error:', error)
        }
    };

    useEffect(() => {
        fetchData(amplifiersUrl, setAmplifiers);
        fetchData(speakersUrl, setSpeakers);
        fetchData(driversUrl, setDrivers);
        fetchData(subwoofersUrl, setSubwoofers);
        fetchData(superTweetersUrl, setSuperTweeters);
        fetchData(lightsUrl, setLights);
    }, []);

    return (
        <main>
            <CartProvider>
            <NavBar/>
            <Routes>
                <Route path="/" element={<> <HeaderIndexImg /> <Breadcrumbs/> <Index key="Index" /> </>} />
                <Route path="/audio" element={<> <HeaderIndexImg /> <Breadcrumbs/> <Audio key="audio" /> </>} />
                <Route path="/lights" element={<> <HeaderIndexImg /> <Breadcrumbs/> <Lights key="lights" lights={lights} /> </>} />
                <Route path="/audio/amplifiers" element={<> <HeaderAmplifiersImg /> <Breadcrumbs/> <Amplifiers key="amplifiers" amplifiers={amplifiers} /> </>} />
                <Route path="/audio/speakers" element={<> <HeaderSpeakersImg /> <Breadcrumbs/> <Speakers key="Speakers" speakers={speakers} /> </>} />
                <Route path="/audio/drivers" element={<> <HeaderSpeakersImg /> <Breadcrumbs/> <Drivers key="Drivers" drivers={drivers} /> </>} />
                <Route path="/audio/subwoofers" element={<> <HeaderSpeakersImg /> <Breadcrumbs/> <Subwoofers key="Subwoofers" subwoofers={subwoofers} /> </>} />
                <Route path="/audio/supertweeters" element={<> <HeaderSpeakersImg /> <Breadcrumbs/> <SuperTweeters key="superTweeters" superTweeters={superTweeters} /> </>} />
                <Route path="/audio/amplifiers/:id" element={<> <HeaderAmplifiersImg /> <Breadcrumbs/> <ShowAmplifier amplifiers={amplifiers} amplifiersUrl={amplifiersUrl} /> </>} />
                <Route path="/audio/speakers/:id" element={<> <HeaderSpeakersImg /> <Breadcrumbs/> <ShowSpeaker key="ShowSpeaker" speakers={speakers} speakersUrl={speakersUrl} /> </>} />
                <Route path="/audio/drivers/:id" element={<> <HeaderSpeakersImg /> <Breadcrumbs/> <ShowDriver key="ShowDriver" drivers={drivers} driversUrl={driversUrl} /> </>} />
                <Route path="/audio/subwoofers/:id" element={<> <HeaderSpeakersImg /> <Breadcrumbs/> <ShowSubwoofer key="ShowSubwoofer" subwoofers={subwoofers} subwoofersUrl={subwoofersUrl} /> </>} />
                <Route path="/lights/:id" element={<> <HeaderIndexImg /> <Breadcrumbs/> <ShowLight lights={lights} lightsUrl={lightsUrl} /> </>} />
                <Route path="/audio/supertweeters/:id" element={<> <HeaderSpeakersImg /> <Breadcrumbs/> <ShowSuperTweeter superTweeters={superTweeters} superTweetersUrl={superTweetersUrl} /> </>} />
                <Route path="/checkout" element={<> <HeaderIndexImg /> <Breadcrumbs/> <Checkout key="Checkout" /> </>} />
                <Route path="/success" element={<> <HeaderIndexImg /> <Success /> </>} />
                <Route path="/cancel" element={<> <HeaderIndexImg /> <Cancel /> </>} />
            </Routes>
            <AboveFooterImg></AboveFooterImg>
            <Footer></Footer>
            </CartProvider>
        </main>
    )
}

export default Main