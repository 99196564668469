const GalleryCdnImgs = [
    { src: "https://i.imgur.com/kXMKbNN.jpg", alt: "Tuned up car" },
    { src: "https://i.imgur.com/hKr9W2I.jpg", alt: "Tuned up car" },
    { src: "https://i.imgur.com/aXcx7hB.jpg", alt: "Tuned up car" },
    { src: "https://i.imgur.com/QvE0M6p.jpg", alt: "Tuned up car" },
    { src: "https://i.imgur.com/CDFxhTX.jpg", alt: "Tuned up car" },
    { src: "https://i.imgur.com/Ik4mZ5w.jpg", alt: "Tuned up car" },
    { src: "https://i.imgur.com/efulVbY.jpg", alt: "Tuned up car" },
]

export { GalleryCdnImgs }