const imgsCdns = [
    "https://i.imgur.com/QCDXs2W.jpg",
    "https://i.imgur.com/vx0AnXV.jpg",
    "https://i.imgur.com/cJmOOJ2.jpg",
    "https://i.imgur.com/zdxeaEy.jpg",
    "https://i.imgur.com/beB9OPr.jpg",
    "https://i.imgur.com/so4Fx3g.jpg",
    "https://i.imgur.com/PMLz4VB.jpg",
    "https://i.imgur.com/asFUbia.jpg",
    "https://i.imgur.com/eRE8LN9.jpg",
    "https://i.imgur.com/oKQDLcF.jpg",
    "https://i.imgur.com/f7Ly63g.jpg",
    "https://i.imgur.com/H2H3Wfz.jpg"
]

export { imgsCdns }