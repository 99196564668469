import { CartContext } from "../CartContext"
import { useContext } from "react"

function CartProducts(props) {
    const cart = useContext(CartContext)
    const id = props.id
    const name = props.name
    const price = props.price
    const quantity = props.quantity
    const category = props.category

    return (
        <>
        <h3>{name}</h3>
        <p>{quantity} total</p>
        <p>${ (quantity * price).toFixed(2) }</p>
        <button className="btn btn-danger" size="sm" onClick={() => cart.deleteFromCart(name)}>Remove</button>
        <hr></hr>
        </>
    )
}

export default CartProducts