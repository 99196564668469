import React from "react";
import './App.css';
import Main from './components/Main';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

function App() {
  return (
    <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}>
      <div className="container-fluid shadow-effect">
        <div className="row shadow-effect">
          <div className="col-12 col-md-12 col-lg-8 col-xl-8 mx-auto px-0 shadow-effect">
            <div className="App">
              <Main />
            </div>
          </div>
        </div>
      </div>
    </PayPalScriptProvider>
  );
}

export default App;
