import React, { useEffect, useState } from "react"
import { useParams, Link } from "react-router-dom"
import { ShowSuperTweetersCdnImgs } from "../utils/ShowSuperTweeterCdnImgs";
import { CartContext } from "../CartContext";
import { useContext } from "react";

const ShowSuperTweeter = (props) => {
    const params = useParams()
    const [superTweeter, setSuperTweeter] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const superTweetersUrl = `${props.superTweetersUrl}/${params.id}`
    const cart = useContext(CartContext)

    useEffect(() => {
        const fetchSuperTweeter = async (props) => {
            try {
                const response = await fetch(`${superTweetersUrl}`)
                if (!response.ok) {
                    throw new Error('Failed to fetch light')
                }
                const data = await response.json()
                setSuperTweeter(data)
            } catch (error) {
                console.error('Error fetching light:', error)
            } finally {
                setIsLoading(false)
            }
        };

        fetchSuperTweeter();
    }, [superTweetersUrl]);

    if (isLoading) {
        return <div className="justify-content-center row text-center p-3 h-50 custom-class">
            {/* <h1>Loading...</h1> */}
            <img src="/SpinLogo.svg" className="App-logo" alt="logo" />
        </div>
    }

    if (!superTweeter) {
        return <div>Super Tweeter not found</div>
    }

    const productQuantity = cart.getProductQuantity(superTweeter.modelName)

    return (
        <div className="row text-center col-12 mx-auto px-0 custom-class">
            <div className='col-12 custom-class'>
                <div className='col-12'>
                </div>
                <div className="row justify-content-center mb-3 mx-auto" key={superTweeter.id}>
                    <div className="col-md-12 col-xl-10">
                        <div className="card shadow-lg border-0 rounded-3" style={{ backgroundColor: "rgba(175, 199, 210, 1)" }}>
                            <div className="card-body">
                                <p className="card-text"><small className="text-body-secondary">{superTweeter.nameDescription}</small></p>
                                <div className="row">
                                    <div className="col-md-6 col-lg-6 col-xl-6 mb-lg-0">
                                        <div className="bg-image hover-zoom ripple rounded ripple-surface">
                                            {/* IMG carousel starts here */}
                                            <div id="superTweeterImageCarousel" className="carousel slide" data-bs-ride="false">
                                                <div className="carousel-inner">
                                                    {ShowSuperTweetersCdnImgs[superTweeter.id - 1].map((image, index) => (
                                                        <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`}>
                                                            <img
                                                                src={image}
                                                                className="d-block img-fluid m-auto mb-5"
                                                                alt={`Slide ${index + 1}`}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                                <button className="carousel-control-prev" type="button" data-bs-target="#superTweeterImageCarousel" data-bs-slide="prev">
                                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Previous</span>
                                                </button>
                                                <button className="carousel-control-next" type="button" data-bs-target="#superTweeterImageCarousel" data-bs-slide="next">
                                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Next</span>
                                                </button>
                                            </div>
                                            {/* IMG Carousel ends here */}
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-xl-6 border-sm-start-none border-start">
                                        <h5 className="mt-2  fw-bold">{superTweeter.modelName}</h5>
                                        <h4 className="mb-1 me-1">$ {superTweeter.price}</h4>
                                        {/* Add DiscountedPrice if available */}
                                        <div className="d-flex flex-column mt-4">
                                            {productQuantity > 0 ?
                                                <>
                                                    <div className="row mt-2 justify-content-center">
                                                        <div className="col-sm-6 align-self-center">In Cart: {productQuantity}</div>
                                                        <button className="btn btn-sm qty-btn col-2 mx-2" onClick={() => cart.removeOneFromCart(superTweeter.modelName)}>-</button>
                                                        <button className="btn btn-sm qty-btn col-2 mx-2" onClick={() => cart.addOneToCart(superTweeter.modelName)}>+</button>
                                                    </div>
                                                </>
                                                :
                                                <button className="btn add-btn btn-sm mt-1" type="button" onClick={() => cart.addOneToCart(superTweeter.modelName, superTweeter.price, superTweeter.id, superTweeter.category)}>
                                                    Add to cart
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card custom-class">
                    <h5 className="card-header fw-bold custome-card-header">TECHNICAL SPECIFICATIONS</h5>
                    <div className="card-body" style={{ backgroundColor: "rgba(175, 199, 210, 1)" }}>
                        <h5 className="card-title fw-bold">{superTweeter.modelName}</h5>
                        <ul className="list-group list-group-flush bg-transparent">
                            <li className="list-group-item bg-transparent">{superTweeter.description1}</li>
                            <li className="list-group-item bg-transparent">{superTweeter.description2}</li>
                            <li className="list-group-item bg-transparent">{superTweeter.description3}</li>
                            <li className="list-group-item bg-transparent">{superTweeter.description4}</li>
                            <li className="list-group-item bg-transparent">{superTweeter.description5}</li>
                            <li className="list-group-item bg-transparent">{superTweeter.description6}</li>
                            <li className="list-group-item bg-transparent">{superTweeter.description7}</li>
                            <li className="list-group-item bg-transparent">Outer Diameter: {superTweeter.outerDiameter}</li>
                            <li className="list-group-item bg-transparent">Cut Out: {superTweeter.cutOut}</li>
                            <li className="list-group-item bg-transparent">Mounting Depth: {superTweeter.mountingDepth}</li>
                        </ul>
                    </div>
                </div>
                <Link to="/audio/supertweeters" className="btn index-btn p-3 mt-3" role="button">BACK</Link>
            </div>
        </div>
    )

};

export default ShowSuperTweeter