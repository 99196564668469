import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { SpeakersCdns } from "../utils/SpeakersCdnsImgs";
import { CartContext } from "../CartContext";
import { useContext } from "react";

const ShowSpeaker = (props) => {
    const params = useParams();
    const [speaker, setSpeaker] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const speakersUrl = `${props.speakersUrl}/${params.id}`;
    const cart = useContext(CartContext);

    useEffect(() => {
        const fetchSpeaker = async () => {
            try {
                const response = await fetch(speakersUrl);
                if (!response.ok) {
                    throw new Error("Failed to fetch speaker");
                }
                const data = await response.json();
                setSpeaker(data);
            } catch (error) {
                console.error("Error fetching speaker:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchSpeaker();
    }, [speakersUrl]);

    if (isLoading) {
        return (
            <div className="justify-content-center row text-center p-3 h-50 custom-class">
                <img src="/SpinLogo.svg" className="App-logo" alt="logo" />
            </div>
        );
    }

    if (!speaker) {
        return <div>Speaker not found</div>;
    }

    const productQuantity = cart.getProductQuantity(speaker.modelName);

    return (
        <div className="row text-center col-12 mx-auto px-0 custom-class">
            <div className="col-12">
                <div className="col-12">
                </div>
                <div className="row justify-content-center mb-3 mx-auto" key={speaker.id}>
                    <div className="col-md-12 col-xl-10">
                        <div className="card shadow-lg border-0 rounded-3" style={{ backgroundColor: "rgba(175, 199, 210, 1)" }}>
                            <div className="card-body">
                                <p className="card-text"><small className="text-body-secondary">{speaker.nameDescription}</small></p>
                                <div className="row">
                                    <div className="col-md-6 col-lg-6 col-xl-6 mb-lg-0">
                                        <div className="bg-image hover-zoom ripple rounded ripple-surface">
                                            <img src={SpeakersCdns[speaker.id - 1]} className="card-img-top" alt="LED system" />
                                            <a href="#!">
                                                <div className="hover-overlay">
                                                    <div className="mask" style={{ backgroundColor: "rgba(253, 253, 253, 0.15)" }}></div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-xl-6 border-sm-start-none border-start">
                                        <h5 className="mt-2  fw-bold">{speaker.modelName}</h5>
                                        <h4 className="mb-1 me-1">$ {speaker.price}</h4>
                                        {/* Add DiscountedPrice if available */}
                                        <div className="d-flex flex-column mt-4">
                                            {productQuantity > 0 ?
                                                <>
                                                    <div className="row mt-2 justify-content-center">
                                                        <div className="col-sm-6 align-self-center">In Cart: {productQuantity}</div>
                                                        <button className="btn btn-sm qty-btn col-2 mx-2" onClick={() => cart.removeOneFromCart(speaker.modelName)}>-</button>
                                                        <button className="btn btn-sm qty-btn col-2 mx-2" onClick={() => cart.addOneToCart(speaker.modelName)}>+</button>
                                                    </div>
                                                </>
                                                :
                                                <button className="btn add-btn btn-sm mt-1" type="button" onClick={() => cart.addOneToCart(speaker.modelName, speaker.price, speaker.id)}>
                                                    Add to cart
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card custom-class">
                    <h5 className="card-header fw-bold custome-card-header">TECHNICAL SPECIFICATIONS</h5>
                    <div className="card-body" style={{ backgroundColor: "rgba(175, 199, 210, 1)" }}>
                        <h5 className="card-title fw-bold">{speaker.modelName}</h5>
                        <ul className="list-group list-group-flush bg-transparent">
                            <li className="list-group-item bg-transparent">{speaker.size}</li>
                            <li className="list-group-item bg-transparent">{speaker.material}</li>
                            <li className="list-group-item bg-transparent">{speaker.cone}</li>
                            <li className="list-group-item bg-transparent">{speaker.magnet}</li>
                            <li className="list-group-item bg-transparent">{speaker.voiceCoilDiameter}</li>
                            <li className="list-group-item bg-transparent">{speaker.nominalImpedance}</li>
                            <li className="list-group-item bg-transparent">{speaker.power}</li>
                            <li className="list-group-item bg-transparent">{speaker.frequencyRange}</li>
                            <li className="list-group-item bg-transparent">{speaker.soundPressure}</li>
                            <li className="list-group-item bg-transparent">{speaker.outerDiameter}</li>
                            <li className="list-group-item bg-transparent">{speaker.cutOut}</li>
                            <li className="list-group-item bg-transparent">{speaker.mountingDepth}</li>
                        </ul>
                    </div>
                </div>
                <Link to="/audio/speakers" className="btn index-btn p-3 mt-3" role="button">BACK</Link>
            </div>
        </div>
    );
};

export default ShowSpeaker;

