import React from "react";
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="container-fluid pt-5 custom-bg text-dark">
            <div className="row text-center pb-4">
                <div className="col-md-4 p-3">
                    <h4>ADDRESS</h4>
                    <p>Lakeland, Florida</p>
                </div>
                <div className="col-md-4 p-3">
                    <h4>EMAIL ADDRESS</h4>
                    <p>info@diamonddesign863.com</p>
                </div>
                <div className="col-md-4 p-3">
                    <h4>PHONE NUMBER</h4>
                    <p>(407)-719-6958</p>
                </div>
                <div className="col-12 text-center p-2">
                    <p>&copy; 2023 - DiamondDesign863 - <Link to="/privacy">Privacy</Link></p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;