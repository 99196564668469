import React from "react";

const AboveFooterImg = () => {
    return (
        <div className="position-relative text-center">
            <img className="img-fluid" src="/Footer.png" style={{ zIndex: 1 }} alt="Footer with company logo" />
            <div className="position-absolute top-50 start-50 translate-middle">
                <h1 className="text-center footer-text display-6">&quot;GET IN TOUCH <br/> TODAY&quot;</h1>
            </div>
        </div>
    )
}

export default AboveFooterImg