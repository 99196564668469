const ShowSubwooferCdnImgs = [
      // -----------------C310D4V3
    ['https://i.imgur.com/deTpxw6.png',
    'https://i.imgur.com/1m1eqvY.png', 
    'https://i.imgur.com/FCvzZx6.png'],
      // -----------------C312D2V3
    ['https://i.imgur.com/x6DdkRf.png', 
    'https://i.imgur.com/DN19tfU.png',
    'https://i.imgur.com/Qvg9N0X.png'],
      // ------------------C412D2
    ['https://i.imgur.com/CSMD1lt.png', 
    'https://i.imgur.com/0u8ShyB.png'],
      // ------------------C412D4
    ['https://i.imgur.com/6Gs6MiA.png', 
    'https://i.imgur.com/6GOvx90.png', 
    'https://i.imgur.com/TYTj02M.png'],
      // -------------------C312D4V3
    ['https://i.imgur.com/AASFmgK.png', 
    'https://i.imgur.com/YOgpv3n.png', 
    'https://i.imgur.com/mgtBNzc.png'],
]

export { ShowSubwooferCdnImgs }