import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { ShowAmplifierCdnImgs } from "../utils/ShowAmplifierCdnImgs";
import { CartContext } from "../CartContext";
import { useContext } from "react";

const ShowAmplifier = (props) => {
    const params = useParams();
    const [amplifier, setAmplifier] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const ampUrl = `${props.amplifiersUrl}/${params.id}`
    const cart = useContext(CartContext)

    useEffect(() => {
        const fetchAmplifier = async (props) => {
            try {
                const response = await fetch(`${ampUrl}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch amplifier');
                }
                const data = await response.json();
                setAmplifier(data);
            } catch (error) {
                console.error('Error fetching amplifier:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchAmplifier();
    }, [ampUrl]);

    if (isLoading) {
        return <div className="justify-content-center row text-center p-3 h-50 custom-class">
            {/* <h1>Loading...</h1> */}
            <img src="/SpinLogo.svg" className="App-logo" alt="logo" />
        </div>
    }

    if (!amplifier) {
        return <div>Amplifier not found</div>;
    }

    const productQuantity = cart.getProductQuantity(amplifier.modelName)

    return (
        <div className="row text-center col-12 mx-auto px-0 custom-class">
            <div className='col-12 custom-class'>
                <div className='col-12'>
                </div>
                <div className="row justify-content-center mb-3 mx-auto" key={amplifier.id}>
                    <div className="col-md-12 col-xl-10">
                        <div className="card shadow-lg border-0 rounded-3" style={{ backgroundColor: "rgba(175, 199, 210, 1)" }}>
                            <div className="card-body">
                                <p className="card-text"><small className="text-body-secondary">{amplifier.nameDescription}</small></p>
                                <div className="row">
                                    <div className="col-md-6 col-lg-6 col-xl-6 mb-lg-0">
                                        <div className="bg-image hover-zoom ripple rounded ripple-surface">
                                            {/* IMG carousel starts here */}
                                            <div id="amplifierImageCarousel" className="carousel slide" data-bs-ride="false">
                                                <div className="carousel-inner">
                                                    {ShowAmplifierCdnImgs[amplifier.id - 1].map((image, index) => (
                                                        <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`}>
                                                            <img
                                                                src={image}
                                                                className="d-block img-fluid m-auto mb-5"
                                                                alt={`Slide ${index + 1}`}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                                <button className="carousel-control-prev" type="button" data-bs-target="#amplifierImageCarousel" data-bs-slide="prev">
                                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Previous</span>
                                                </button>
                                                <button className="carousel-control-next" type="button" data-bs-target="#amplifierImageCarousel" data-bs-slide="next">
                                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Next</span>
                                                </button>
                                            </div>
                                            {/* IMG Carousel ends here */}
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-xl-6 border-sm-start-none border-start">
                                        <h5 className="mt-2  fw-bold">{amplifier.modelName}</h5>
                                        <h4 className="mb-1 me-1">$ {amplifier.price}</h4>
                                        {/* Add DiscountedPrice if available */}
                                        <div className="d-flex flex-column mt-4">
                                            {productQuantity > 0 ?
                                                <>
                                                    <div className="row mt-2 justify-content-center">
                                                        <div className="col-sm-6 align-self-center">In Cart: {productQuantity}</div>
                                                        <button className="btn btn-sm qty-btn col-2 mx-2" onClick={() => cart.removeOneFromCart(amplifier.modelName)}>-</button>
                                                        <button className="btn btn-sm qty-btn col-2 mx-2" onClick={() => cart.addOneToCart(amplifier.modelName)}>+</button>
                                                    </div>
                                                </>
                                                :
                                                <button className="btn add-btn btn-sm mt-1" type="button" onClick={() => cart.addOneToCart(amplifier.modelName, amplifier.price, amplifier.id)}>
                                                    Add to cart
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card custom-class">
                    <h5 className="card-header custome-card-header fw-bold">TECHNICAL SPECIFICATIONS</h5>
                    <div className="card-body" style={{ backgroundColor: "rgba(175, 199, 210, 1)" }}>
                        <h5 className="card-title fw-bold">{amplifier.modelName}</h5>
                        <ul className="list-group list-group-flush bg-transparent">
                            <li className="list-group-item bg-transparent">Power @ 4Ω: {amplifier.powerAt4Ohms}</li>
                            <li className="list-group-item bg-transparent">Power @ 2Ω: {amplifier.powerAt2Ohms}</li>
                            <li className="list-group-item bg-transparent">Power @ 1Ω: {amplifier.powerAt1Ohm}</li>
                            <li className="list-group-item bg-transparent">Bridge Power @ 4Ω: {amplifier.bridgePowerAt40hms}</li>
                            <li className="list-group-item bg-transparent">Bridge Power @ 2Ω: {amplifier.bridgePowerAt20hms}</li>
                            <li className="list-group-item bg-transparent">Operating Voltage: {amplifier.operatingVoltage}</li>
                            <li className="list-group-item bg-transparent">Current Draw (Music): {amplifier.currentDrawMusic}</li>
                            <li className="list-group-item bg-transparent">Current Draw (Max) {amplifier.currentDrawMax}</li>
                            <li className="list-group-item bg-transparent">Recommended Fuse (Music): {amplifier.recommendedFuseMusic}</li>
                            <li className="list-group-item bg-transparent">Total Efficiency: {amplifier.currentDrawMusic}</li>
                            <li className="list-group-item bg-transparent">Damping Factor: {amplifier.currentDrawMax}</li>
                            <li className="list-group-item bg-transparent">Frequency Response (-3dB): {amplifier.frequencyResponseMinus3dB}</li>
                            <li className="list-group-item bg-transparent">SNR: {amplifier.signalToNoiseRatio}</li>
                            <li className="list-group-item bg-transparent">Crossover HPF: {amplifier.crossoverHPF}</li>
                            <li className="list-group-item bg-transparent">Crossover LPF:  {amplifier.crossoverLPF}</li>
                            <li className="list-group-item bg-transparent">THD + N (10% Rated Power): {amplifier.thdPlusN}</li>
                            <li className="list-group-item bg-transparent">Product Dimensions: {amplifier.productDimensions1}</li>
                            <li className="list-group-item bg-transparent">Product Dimensions: {amplifier.productDimensions2}</li>
                        </ul>
                    </div>
                </div>
                <Link to="/audio/amplifiers" className="btn index-btn p-3 mt-3" role="button">BACK</Link>
            </div>
        </div>
    )

};

export default ShowAmplifier;