import React from "react";
import { Link } from 'react-router-dom'
import { useState, useContext } from "react"
import { CartContext } from "../CartContext";
import CartProducts from "./CartProducts";

const NavBar = (props) => {
  const cart = useContext(CartContext)

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const productsCount = cart.items.reduce((sum, product) => {
    if (product.quantity && typeof product.quantity === 'number') {
      return sum + product.quantity;
    } else {
      return sum;
    }
  }, 0);

  return (
    <>
      <div className='bg-dark mb-0 pb-0 fixed-top'>
        <header className="custom-header-color">
          <nav className="navbar navbar-expand-sm navbar-toggleable-sm navbar-dark box-shadow shadow-sm">
            <div className="container-fluid">
              <Link className="navbar-brand" to="/">
                <img src="/WingsWebLogo.png" alt="Logo" width="30" height="24" className="d-inline-block align-text-top" />
                DiamondDesign863
              </Link>
              {/* yohAN */}
              <div className="top-cart" onClick={handleShow}>
                <i className="bi bi-cart3 text-white fw-bold">{productsCount}</i>
              </div>
              {/* yohAN */}
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="navbar-collapse collapse d-sm-inline-flex justify-content-between text-end">
                <ul className="navbar-nav flex-grow-1 justify-content-end">
                  <li className="nav-item">
                    <Link className="nav-link text-white" to="/">Home</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link text-white" to="/about">About Us</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link text-white" to="/team">Team</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link text-white" to="/events">Events</Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link className="nav-link text-white" onClick={handleShow}>
                      <i className="bi bi-cart3">Cart {productsCount} Items</i>
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </nav>
          <div className={`modal fade ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }} tabIndex="-1" aria-hidden={!show}>
            <div className="modal-dialog">
              <div className="modal-content" style={{ backgroundColor: "rgba(175, 199, 210, 1)" }}>
                <div className="modal-header">
                  <h5 className="modal-title">Shopping Cart</h5>
                  <button type="button" className="btn-close" onClick={handleClose}></button>
                </div>
                <div className="modal-body">
                  {productsCount > 0 ?
                    <>
                      <p>Items in your cart:</p>
                      {cart.items.map((currentProduct, idx) => (
                        <CartProducts key={idx} id={currentProduct.id} name={currentProduct.name} quantity={currentProduct.quantity} category={currentProduct.category} price={currentProduct.price}></CartProducts>
                      ))}

                      <h1>Total: {cart.getTotalCost().toFixed(2)}</h1>

                      <Link to="/checkout" className="btn btn-success btn-sm mt-1" onClick={handleClose}>
                        Checkout
                      </Link>

                    </>
                    :
                    <h1>There are no items in your cart</h1>}
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    </>
  )
}

export default NavBar