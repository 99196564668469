const ShowDriverCdnImgs = [
    ['https://i.imgur.com/DoXZFSL.png',
    'https://i.imgur.com/Ym2Uu7z.png', 
    'https://i.imgur.com/ozxVAU4.png'],

    ['https://i.imgur.com/emMn7XW.png', 
    'https://i.imgur.com/LY0P29F.png',
    'https://i.imgur.com/TDs2JVa.png'],
    
    ['https://i.imgur.com/C8YNi6s.png', 
    'https://i.imgur.com/rR5dKgF.png', 
    'https://i.imgur.com/HhjrFnc.png']
]

export { ShowDriverCdnImgs }