const AmplifiersCdnImgs = [
    //---400.2 EVOX2 – 4Ω
    'https://i.imgur.com/wyir6da.png',

    //---400.4 EVOX2 – 4Ω
    'https://i.imgur.com/x8OMZx8.png',

    //---800.4 EVOX2 – 4Ω
    'https://i.imgur.com/ZzJ9I1V.png',

    //---1000.1 EVOX2 – 1Ω&2Ω REPEAT
    'https://i.imgur.com/ylGJXDU.png',
    'https://i.imgur.com/ylGJXDU.png',

    //---1200.2 EVOX2 – 2Ω&4Ω REPEAT
    'https://i.imgur.com/W9da7sC.png',
    'https://i.imgur.com/W9da7sC.png',

    //---1200.4 EVOX2 – 2Ω&4Ω REPEAT
    'https://i.imgur.com/41zemsy.png',
    'https://i.imgur.com/41zemsy.png',

    //---1600.1 EVOX2 – 1Ω&2Ω REPEAT
    'https://i.imgur.com/ojewQxh.png',
    'https://i.imgur.com/ojewQxh.png',

    //---2400.4 EVOX2 – 2Ω&4Ω REPEAT
    'https://i.imgur.com/pyjil47.png',
    'https://i.imgur.com/pyjil47.png',

    //---3000.1 EVOX2 – 1Ω&2Ω REPEAT
    'https://i.imgur.com/bUwsZDn.png',
    'https://i.imgur.com/bUwsZDn.png',

    //---5000.1 EVOX2 – 1Ω&2Ω REPEAT
    'https://i.imgur.com/vhd5mHK.png',
    'https://i.imgur.com/vhd5mHK.png',

    //---8000.1 EVOX2 – 1Ω&2Ω REPEAT
    'https://i.imgur.com/0imHFgA.png',
    'https://i.imgur.com/0imHFgA.png',

    //---12000.1 EVOX – 1Ω&2Ω REPEAT
    'https://i.imgur.com/MBFWN70.png',
    'https://i.imgur.com/MBFWN70.png'
]

export { AmplifiersCdnImgs }