const ShowLightCdnImgs = [
    //--1B
    ['https://video.wixstatic.com/video/2594fc_b97f8b7a5d714ef4ab1902196bdc07a4/1080p/mp4/file.mp4',
    'https://i.imgur.com/QCDXs2W.jpg'],

    //--1S
    ['https://video.wixstatic.com/video/2594fc_911185ccdadc45e7b1d2339fba74ef65/1080p/mp4/file.mp4',
    'https://i.imgur.com/vx0AnXV.jpg'],
    
    //--1E1
    ['https://video.wixstatic.com/video/2594fc_dd452791595945cf94d64b0a133ad0d6/480p/mp4/file.mp4',
    'https://i.imgur.com/cJmOOJ2.jpg'],

    //--1A3
    ['https://video.wixstatic.com/video/2594fc_3288ed35fc984f1e9022a19a985d92d6/1080p/mp4/file.mp4',
    'https://i.imgur.com/cJmOOJ2.jpg'],

    //--1C
    ['https://video.wixstatic.com/video/2594fc_5e2673b134d74c99bd42a3acc301c596/1080p/mp4/file.mp4',
    'https://i.imgur.com/cJmOOJ2.jpg'],

    //--1I
    ['https://video.wixstatic.com/video/2594fc_f066d0abd3e1415a83fcf30913f0c49b/1080p/mp4/file.mp4',
    'https://i.imgur.com/cJmOOJ2.jpg'],

    //--1J
    ['https://video.wixstatic.com/video/2594fc_99a95b72be7e40788efbb88efb60560e/1080p/mp4/file.mp4',
    'https://i.imgur.com/cJmOOJ2.jpg'],

    //--2A2
    ['https://video.wixstatic.com/video/2594fc_61dbb1a37bac46e89f4c18400d9333e1/1080p/mp4/file.mp4',
    'https://i.imgur.com/cJmOOJ2.jpg'],

    //--24B1
    ['https://video.wixstatic.com/video/2594fc_33681cec7e354123b5465d62a6dcccdc/1080p/mp4/file.mp4',
    'https://i.imgur.com/eRE8LN9.jpg'],

    //--24B
    ['https://video.wixstatic.com/video/2594fc_33681cec7e354123b5465d62a6dcccdc/1080p/mp4/file.mp4',
    'https://i.imgur.com/oKQDLcF.jpg'],

    //--21B
    ['https://video.wixstatic.com/video/2594fc_33681cec7e354123b5465d62a6dcccdc/1080p/mp4/file.mp4',
    'https://i.imgur.com/f7Ly63g.jpg'],

    //--21C
    ['https://video.wixstatic.com/video/2594fc_33681cec7e354123b5465d62a6dcccdc/1080p/mp4/file.mp4',
    'https://i.imgur.com/H2H3Wfz.jpg'],
]

export { ShowLightCdnImgs }